import React from "react"
import { ProjectLayout } from "../../components/common/ProjectsLayout"
import { useStaticQuery, graphql } from "gatsby"
import { PROJECT_TUFAS_SEO } from "../../config/siteMetaData"

const TufasPage = () => {
  const data = useStaticQuery(graphql`
    query {
      tufasProject: file(relativePath: { eq: "new_tufas.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ProjectLayout
      image={data.tufasProject.childImageSharp.fluid}
      header={
        <a
          href="https://tufasboulderlounge.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          tufasboulderlounge.com
        </a>
      }
      intro="
      Tufas Boulder Lounge is a rock climbing gym in New Kensington, Philly. 
      A well-respected establishment for any level of climber. 
      Besides their main attention to the rock climbing community, 
      they are actively advocating for POC and the LGTBQ+ within and outside the climbing world."
      body="
      While living in Philly I was a member at this gym but noticed points of improvements for their website.
      I reached out the Day Manager and Owner of the gym and asked if they'd be willing to hire me to help improve the site. 
      They agreed and I improved SEO, UX and UI experience. Even took it upon myself to help redesign their logo, to represent what the gym stands for."
      conclusion="I thorougly enjoyed being an independent contractor for the climbing gym, it was a difficult task to work 
      full-time as well as a side project but I knew with a couple months of working on their site, I'd be able to improve user-experience drastically. 
      I am most proud of myself for proactively reaching out to an established company seeking to help with the skills and knowledge I poccess."
      tech="Figma, Wordpress, HTML5, CSS3"
      {...PROJECT_TUFAS_SEO}
    />
  )
}

export default TufasPage
